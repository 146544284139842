import React, { Component } from 'react';
import ProblemReport from './ProblemReport.jsx';
import FreeMovement from './FreeMovement.jsx';
import AutomaticMovement from './AutomaticMovement.jsx';
import { UserStatistics } from '../entities/UserStatistics.js';
import { URLParams } from '../entities/URLParams.js';
import { getTS } from '../helpers/utils.js';
import spinner from '../assets/spinner.gif';
import { api } from '../api';
import { responsiveness } from '../helpers/responsiveness.js';

window._THRESHOLD = 0.3;

export default class Store extends Component {
  urlParams = null;
  jumpToTime = 0;

  constructor(props) {
    super(props);
    this.state = {
      project: null,
      videoWidth: responsiveness.calcVideoWidth(),
      videoHeight: responsiveness.calcVideoHeight()
    };
  };

  initStore = userid => {
    this.jumpToTime = this.urlParams.get('jumpToTime');
    let data = this.jumpToTime ? { userid, jumpToTime: this.jumpToTime } : { userid };
    api.loadCell(data)
      .then(response => {
        let project = JSON.parse(response.data);
        this.setState({ project });
        this.userStatistics = new UserStatistics(project.userControls);
      });
    const currentUrlParams = new URLSearchParams(window.location.search);

    const isRedirectedFromShelf = currentUrlParams.get('si');
    
    if (isRedirectedFromShelf) {
      const url = window.location.href;
      const data = { url }
      fetch(process.env.REACT_APP_API_DECIPHER, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
    }
  };

  render() {
    let commonProps = {
      jumpToTime: this.jumpToTime
    }
    return (
      <div id="store">
        {
          this.state.project ?
            <div className="store-wrapper">
              {
                this.state.project.isReportingProblem ?
                  <ProblemReport getTS={getTS} getVideoData={this.getVideoData} isVisible={this.state.project.isReportingProblem} />
                  :
                  null
              }
              {
                this.state.project.userControls ?
                  <FreeMovement {...commonProps} {...this.state} />
                  :
                  <AutomaticMovement {...commonProps} {...this.state} />
              }
            </div>
            :
            <div className="spinner-wrapper">
              <img src={spinner} alt="Please, wait..." />
            </div>
        }
      </div>
    )
  };

  componentDidMount() {
    this.urlParams = new URLParams();
    let userid = this.urlParams.get('userid');
    if (!userid) return;
    this.initStore(userid);
  }
}
